import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const COURSES_API =
  process.env.REACT_APP_SHOW_COURSE_API_URL +
  `?locale=${localStorage.getItem("i18nextLng")}`;
const PACKAGES_API =
  process.env.REACT_APP_SHOW_PACKAGE_AS_SHOW_COURSE +
  `?locale=${localStorage.getItem("i18nextLng")}`;

export const SubscriptionItemDT = createAsyncThunk(
  "ItemDTSlice/SubscriptionItemDT",
  async ({ id, whatItem }, { rejectWithValue }) => {
    try {
      if (whatItem === "courses") {
        const response = await axios.post(COURSES_API, { course_id: id });
        return response.data.data;
      } else if (whatItem === "packages") {
        const response = await axios.post(PACKAGES_API, { package_id: id });
        return response.data.data;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.data?.course_id?.[0] ||
          `SORRY , ${whatItem.toUpperCase()} is not Available`
      );
    }
  }
);

const ItemDTSlice = createSlice({
  name: "ItemDTSlice",
  initialState: {
    item: {},
    reviews: [],
    relatedItems: [],
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(SubscriptionItemDT.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(SubscriptionItemDT.fulfilled, (state, action) => {
        const {
          course,
          reviews,
          related_courses,
          package: packageDT,
          related_packages,
        } = action.payload;
        state.item = course ?? packageDT ?? null;
        state.reviews = reviews ?? [];
        state.relatedItems = related_courses ?? related_packages ?? [];
        state.loading = false;
      })

      .addCase(SubscriptionItemDT.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const NowItem = (state) => state.ItemDT.item;
export const NowItemReviews = (state) => state.ItemDT.reviews;
export const NowItemRelatedCourses = (state) => state.ItemDT.relatedItems;
export const LoadingItemDetails = (state) => state.ItemDT.loading;
export const ErrorItemDetails = (state) => state.ItemDT.error;

export default ItemDTSlice.reducer;
