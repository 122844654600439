import { useTranslation } from "react-i18next";

export const CategoryBox = ({ data, handleChange, title }) => {
  const { t } = useTranslation();
  return (
    <div className="p-3 rounded-lg bg-[#F7F7F9] text-black tab:w-full">
      <h1 className="f-bold text-xl">{title}</h1>
      <div>
        <div className="w-full">
          <input
            type="checkbox"
            value={""}
            name={title}
            id={0}
            onChange={handleChange}
          />
          <label htmlFor={0} className="f-rg mx-2 text-nowrap">
            {t("allCat")}
          </label>
        </div>
        {data &&
          data?.map((arrItem) => (
            <div key={arrItem?.id} className="w-full">
              <input
                type="checkbox"
                value={arrItem?.id}
                name={title}
                id={`${title}-${arrItem?.id}`}
                onChange={handleChange}
                aria-label={arrItem?.translations?.[0]?.name || "Category"}
              />
              <label
                htmlFor={`${title}-${arrItem?.id}`}
                className="f-rg mx-2 text-nowrap"
              >
                {arrItem?.translations?.[0]?.name}
              </label>
            </div>
          ))}
      </div>
    </div>
  );
};
