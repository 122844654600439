export const PriceRadio = ({ arrTitle, data, handleChange }) => {
  return (
    <div className="p-3 rounded-lg bg-[#F7F7F9] text-black tab:w-full">
      <h1 className="f-bold text-xl">{arrTitle}</h1>
      <div>
        {data?.map((arrItem) => (
          <div key={arrItem?.id} className="w-full">
            <input
              type="checkbox"
              value={arrItem?.id}
              name={arrTitle}
              id={`${arrTitle}-${arrItem?.id}`}
              onChange={handleChange}
            />
            <label
              htmlFor={`${arrTitle}-${arrItem?.id}`}
              className="f-rg mx-2 text-nowrap"
            >
              {arrItem?.name}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};
