import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
const Url =
  process.env.REACT_APP_API_URL +
  `/courses?locale=${localStorage.getItem("i18nextLng") || "ar"}`;

const user_id = Cookies.get("user")
  ? JSON.parse(Cookies.get("user"))?.id
  : null;

const initialState = {
  courses: [],
  filteredCourses: [],
  courseDetails: [],
  error: null,
  loading: true,
};
export const GetCourses = createAsyncThunk(
  "courses",
  async (queryString, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${Url}&user_id=${user_id}`);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const GetFilteredCourses = createAsyncThunk(
  "filteredCourses",
  async (params, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${Url}&user_id=${user_id}&${params}`);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const GetCourseDetails = createAsyncThunk(
  "courseDetails",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`/course/${id}`);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const coursesSlice = createSlice({
  name: "coursesSlice",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(GetCourses.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetCourses.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.courses = action?.payload;
      })
      .addCase(GetCourses.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.courses = action?.payload;
      })
      // filtered courses
      .addCase(GetFilteredCourses.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetFilteredCourses.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.filteredCourses = action?.payload;
      })
      .addCase(GetFilteredCourses.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.filteredCourses = action?.payload;
      })
      // course details
      .addCase(GetCourseDetails.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetCourseDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.courseDetails = action?.payload;
      })
      .addCase(GetCourseDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.courseDetails = action?.payload;
      });
  },
});
export const coursesReducer = coursesSlice.reducer;
export const coursesActions = coursesSlice.actions;
