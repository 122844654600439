import { useTranslation } from "react-i18next";
import RatingComponent from "../RatingComponent";

export const RatingRadio = ({ arrTitle, data, handleChange }) => {
  const { t } = useTranslation();
  return (
    <div className="p-3 rounded-lg bg-[#F7F7F9] text-black tab:w-full">
      <h1 className="f-bold text-xl">{arrTitle}</h1>
      <div>
        <div className="w-full">
          <input
            type="checkbox"
            value={""}
            name={arrTitle}
            id={0}
            onChange={handleChange}
          />
          <label htmlFor={0} className="f-rg mx-2 text-nowrap">
            {t("allCat")}
          </label>
        </div>
        {data?.map((rate, index) => (
          <div key={index} className="my-2">
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                value={rate}
                name={`rate`}
                id={`rate-${rate}`}
                onChange={handleChange}
              />
              <RatingComponent rate={rate} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
