import React from "react";
import { useTranslation } from "react-i18next";

const WhatWeDo = () => {
  const { t } = useTranslation();
  return (
    <section className="my-5">
      <div className="bg-[#2A2A2B] rounded-t-[5rem] text-white text-center p-8">
        <div>
          <h1 className="f-bold text-2xl">
            {t("helpPpl.0")}{" "}
            <span className="text-[#CD1818] f-bold">{t("helpPpl.1")}</span>{" "}
            {t("helpPpl.2")}
          </h1>
        </div>
        <div dir="ltr" className="flex justify-center gap-8 mt-8 items-center">
          <div>
            <p className="text-4xl my-0 font-bold">
              45K <span className="text-[#CD1818]">+</span>
            </p>
            <p className="f-bold">{t("nowSubsc")}</p>
          </div>
          <hr className="bg-white rotate-180 h-20 w-px" />
          <div>
            <p className=" text-4xl my-0 font-bold">
              328 <span className="text-[#CD1818]">+</span>
            </p>
            <p className="f-bold">{t("satisSubsc")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatWeDo;
