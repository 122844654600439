import { useRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import PackageBox from "../Packages/PackageBox";
import "swiper/css";
import { useSelector } from "react-redux";
import {
  HomeErr,
  HomeLoading,
  PackagesSelector,
} from "../../store/slices/HomeSlice";
import LoadingComponent from "../../UI/CustomUI/LoadingComponent";
import { useTranslation } from "react-i18next";

const PackagesSwiper = ({
  boxClassName,
  slidesPerView,
  seeFavourite,
  swiperClassName,
  imgClass,
  mainBox,
  oneSlide,
}) => {
  const { t } = useTranslation();
  const swiperRef = useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const packages = useSelector(PackagesSelector);
  const loading = useSelector(HomeLoading);
  const error = useSelector(HomeErr);

  useEffect(() => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;

      const updateButtonState = () => {
        setIsBeginning(swiper.isBeginning);
        setIsEnd(swiper.isEnd);
      };

      swiper.on("slideChange", updateButtonState);
      updateButtonState();

      return () => {
        swiper.off("slideChange", updateButtonState);
      };
    }
  }, []);

  if (packages.length < 1 || error) {
    return (
      <div className="f-bold text-[#CD1818] text-2xl pt-20">
        {t("soryyNoData")}
      </div>
    );
  }

  if (!packages && loading) {
    return (
      <LoadingComponent
        parentClass={`grid gap-3 p-4 grid-cols-${slidesPerView}`}
        childClass={`h-[25rem]`}
        childrenNumber={slidesPerView}
        mainBox={mainBox}
      />
    );
  }

  return (
    <section className="flex items-center gap-3 ">
      <button
        onClick={() => {
          if (swiperRef.current) {
            swiperRef.current.swiper.slidePrev();
          }
        }}
        className={`swiper-btn ${
          isBeginning ? "opacity-40 cursor-not-allowed" : ""
        }`}
        disabled={isBeginning}
      >
        {
          <i
            className={`fa-solid ${
              localStorage.getItem("i18nextLng") === "en"
                ? "fa-chevron-left"
                : "fa-chevron-right"
            } arrow`}
          ></i>
        }
      </button>
      <Swiper
        ref={swiperRef}
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        pagination={{ clickable: true }}
        breakpoints={
          !oneSlide && {
            576: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            767: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            1600: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
          }
        }
        scrollbar={{ draggable: true }}
        className={swiperClassName}
        spaceBetween={10}
      >
        {packages?.map((pack) => (
          <SwiperSlide key={pack?.id}>
            <PackageBox
              pack={pack}
              boxClassName={boxClassName}
              slidesPerView={slidesPerView}
              seeFavourite={seeFavourite}
              imgClass={imgClass}
              mainBox={mainBox}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <button
        onClick={() => {
          if (swiperRef.current) {
            swiperRef.current.swiper.slideNext();
          }
        }}
        className={`swiper-btn ${isEnd ? "opacity-40 cursor-not-allowed" : ""}`}
        disabled={isEnd}
      >
        {
          <i
            className={`fa-solid ${
              localStorage.getItem("i18nextLng") === "en"
                ? "fa-chevron-right"
                : "fa-chevron-left"
            } arow`}
          ></i>
        }
      </button>
    </section>
  );
};

export default PackagesSwiper;
