import React from "react";
import AboutContent from "../About/aboutContent";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HomeAbout = () => {
  const { t } = useTranslation();
  return (
    <section className="my-7">
      <div className="flex flex-col items-center w-fit">
        <h1 className="f-bold text-2xl ">{t("aboutUs")} </h1>
        <div>
          <img src="/imgs/curve.png" alt="curve" className="w-auto" />
        </div>
      </div>
      <AboutContent
        slot={
          <Link to={`/packages`}>
            <button className="animate-btn">
              <i className="fas fa-play"></i>
              {t("aboutPackages")}
            </button>
          </Link>
        }
      />
    </section>
  );
};

export default HomeAbout;
