import { useTranslation } from "react-i18next";
import IsTabletOrLarger from "../../hooks/UIHooks/isTabletOrLarger";
import IsSmLapOrLarger from "../../hooks/UIHooks/isSmLapOrLarger";
import NavComponent from "../../UI/CustomUI/NavComponent";
import PackagesSwiper from "../../components/Home/packageSwiper";

const Packages = () => {
  const isTabletOrLarger = IsTabletOrLarger();
  const isSmLapOrLarger = IsSmLapOrLarger();
  const { t } = useTranslation();
  return (
    <>
      <NavComponent firstName={t("packages")} firstURL={`/packages`} />
      <main className="p-6 mob:p-4">
        <div className="flex flex-col items-center w-fit my-3 mx-6 tab:mx-0">
          <h1 className="f-ex-bold text-2xl ">{t("packages")}</h1>
          <div>
            <img src="/imgs/curve.png" alt="curve" className="w-auto" />
          </div>
        </div>
        <main className="my-12">
          <PackagesSwiper
            boxClassName={`grid`}
            slidesPerView={isSmLapOrLarger ? 4 : isTabletOrLarger ? 2 : 1}
            seeFavourite={true}
            swiperClassName={`w-full items-center h-[39rem] tab:h-full mob:h-full`}
            // imgClass={`h-[20rem] w-full tab:h-[40rem] mob:h-[26rem]`}
          />
        </main>
      </main>
    </>
  );
};
export default Packages;
