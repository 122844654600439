import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
const course_quiz_url =
  process.env.REACT_APP_API_URL +
  `/show_course_quizs?locale=${localStorage.getItem("i18nextLng") || "ar"}`;

const package_quiz_url =
  process.env.REACT_APP_API_URL +
  `/show_package_quizs?locale=${localStorage.getItem("i18nextLng") || "ar"}`;

const user_id = Cookies.get("user")
  ? JSON.parse(Cookies.get("user"))?.id
  : null;

const initialState = {
  courseQuiz: [],
  packageQuiz: [],
  error: null,
  loading: true,
};
export const GetCourseQuiz = createAsyncThunk(
  "courseQuiz",
  async (day_id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.post(`${course_quiz_url}`, { day_id: day_id });
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const GetPackageQuiz = createAsyncThunk(
  "packageQuiz",
  async (day_id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.post(`${package_quiz_url}`, { day_id: day_id });
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const quizSlice = createSlice({
  name: "quizSlice",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(GetCourseQuiz.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetCourseQuiz.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.courseQuiz = action?.payload;
      })
      .addCase(GetCourseQuiz.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.courseQuiz = action?.payload;
      })
      // get package quiz
      .addCase(GetPackageQuiz.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetPackageQuiz.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.packageQuiz = action?.payload;
      })
      .addCase(GetPackageQuiz.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.packageQuiz = action?.payload;
      });
  },
});
export const quizReducer = quizSlice.reducer;
export const quizActions = quizSlice.actions;
