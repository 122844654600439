import React, { useState } from "react";
import { Pagination, PaginationItem } from "@mui/material";
const CustomPagination = ({ totalPages, currentPage, onChange }) => {
  const [page, setPage] = useState(currentPage);

  const handlePageChange = (event, value) => {
    if (value !== page) {
      setPage(value);
      onChange(value);
    } else {
      return;
    }
  };

  return (
    <Pagination
      count={totalPages || 1}
      page={page || 1}
      onChange={handlePageChange}
      renderItem={(props) => <PaginationItem {...props} />}
      size="large"
      // siblingCount={0}
      boundaryCount={1}
      className="mui-pagination flex justify-center"
    />
  );
};

export default CustomPagination;
