import NavComponent from "../UI/CustomUI/NavComponent";
import SignUpHook from "../hooks/Auth/SignUpHook";

const Register = () => {
  const { loggedInMSG, error, t, register, formDT, MakeChanges, loading } =
    SignUpHook();

  return (
    <>
      {loggedInMSG ? (
        <div className="f-bold  h-dvh w-full text-3xl flex flex-col gap-4 justify-center items-center bg-white z-40">
          <div className="text-center">{t("alreadySigned")}</div>
          <div className="text-center">{t("navigateYou")}</div>
        </div>
      ) : (
        <>
          <NavComponent firstName={t("createEmail")} firstURL={`/register`} />
          <main className="p-6 mob:p-0">
            <div className="flex flex-col items-center w-fit p-3">
              <h1 className="f-ex-bold text-2xl ">{t("createEmail")}</h1>
              <div>
                <img src="/imgs/curve.png" alt="curve" className="w-auto" />
              </div>
            </div>
            <section className="flex justify-center items-center my-8">
              <section
                className={`my-22 mx-auto p-4 shadow-md rounded w-2/4 tab:w-full mob:p-2 dataBox`}
              >
                <div className="flex flex-col items-center w-fit">
                  <h1 className="f-ex-bold text-2xl ">{t("createNow")}</h1>
                  <div>
                    <img src="/imgs/curve.png" alt="curve" className="w-auto" />
                  </div>
                </div>
                <p className="f-semi-bold mx-3 my-2 text-gray-500 ">
                  {t("registerPara")}
                </p>
                {/* FORM */}
                <form method="POST" onSubmit={register}>
                  <div className="flex items-center gap-3 px-2 w-full mob:flex-col">
                    {/* FIRST NAME */}
                    <div className="w-full">
                      <label htmlFor="firstName" className="f-bold text-xl">
                        {t("firstName")}
                      </label>{" "}
                      <br />
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={formDT.firstName}
                        onChange={MakeChanges}
                        placeholder={t("writeFirst")}
                        className="my-2 border rounded-sm outline-none f-rg px-2 py-1 shadow-sm w-full"
                        aria-required={true}
                        required
                      />
                    </div>
                    {/* LAST NAME */}
                    <div className="w-full">
                      <label htmlFor="lastName" className="f-bold text-xl">
                        {t("lastName")}
                      </label>{" "}
                      <br />
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={formDT.lastName}
                        onChange={MakeChanges}
                        placeholder={t("writeLast")}
                        className="my-2 border rounded-sm outline-none f-rg px-2 py-1 shadow-sm w-full"
                        aria-required={true}
                        required
                      />
                    </div>
                  </div>
                  {/* EMAIL */}
                  <div className="px-2">
                    <label htmlFor="email" className="f-bold text-xl">
                      {t("email")}
                    </label>{" "}
                    <br />
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formDT.email}
                      onChange={MakeChanges}
                      placeholder={t("writeEmail")}
                      className="my-2 border rounded-sm outline-none f-rg px-2 py-1 shadow-sm w-full"
                      aria-required={true}
                      required
                    />
                  </div>
                  {/* PASSWORD */}
                  <div className="px-2">
                    <label htmlFor="password" className="f-bold text-xl">
                      {t("pass")}
                    </label>{" "}
                    <br />
                    <input
                      type="password"
                      id="password"
                      name="password"
                      value={formDT.password}
                      onChange={MakeChanges}
                      placeholder={t("writePass")}
                      className="my-2 border rounded-sm outline-none f-rg px-2 py-1 shadow-sm w-full"
                      aria-required={true}
                      required
                    />
                  </div>
                  {/* CHECKBOX (REMEMBER-ME) */}
                  <div className="my-2 px-2">
                    <input
                      type="checkbox"
                      name="rememberMe"
                      id="remember-me"
                      checked={formDT.rememberMe}
                      onChange={MakeChanges}
                    />
                    <label
                      htmlFor="remember-me"
                      className="f-bold text-sm mx-2"
                    >
                      {t("rememberMe")}
                    </label>
                  </div>
                  <div>
                    <button className="submit-btn" type="submit">
                      {loading ? (
                        <div className="flex justify-center">
                          <span className="mx-2 f-bold">{t("loginning")}</span>
                          <div className="animate-spin rounded-full border-t-2 border-b-2 border-white h-7 w-7 mr-2"></div>
                        </div>
                      ) : (
                        <div>
                          <span className="mx-2 f-bold">{t("login")}</span>{" "}
                          <i className="fa-solid fa-arrow-left"></i>
                        </div>
                      )}
                    </button>
                  </div>
                </form>
              </section>
            </section>
            {loading === false
              ? error !== "" && (
                  <p className="text-[#CD1818] text-xl text-center">{error}</p>
                )
              : null}
          </main>
        </>
      )}
    </>
  );
};

export default Register;
