import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SubscriptionsBox from "../../components/Subscriptions/SubscriptionsBox";
import NavComponent from "../../UI/CustomUI/NavComponent";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "../../UI/CustomUI/LoadingComponent";
import EmptySubscriptions from "../../components/Subscriptions/EmptySubscriptions";
import {
  profileDataFetching,
  UserCoursesSelector,
  UserErr,
  UserLoading,
  UserPackagesSelector,
} from "../../store/slices/ProfileSlice";
import { useTranslation } from "react-i18next";

const SubscriptionsView = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(profileDataFetching());
  }, [dispatch]);

  const [secState, setSecState] = useState("packages");
  const courses = useSelector(UserCoursesSelector);
  const packages = useSelector(UserPackagesSelector);
  const loading = useSelector(UserLoading);
  const error = useSelector(UserErr);

  if (loading)
    return (
      <LoadingComponent
        parentClass={`grid grid-cols-3 gap-3 p-4`}
        childClass={[
          "h-[10rem] mb-24 w-full rounded-lg col-span-3",
          "h-[4rem] w-full rounded-lg col-span-3",
          "h-[4rem] w-full rounded-lg col-span-3",
          "h-[4rem] w-full rounded-lg col-span-3",
        ]}
        childrenNumber={4}
      />
    );

  if (error)
    return (
      <div className="text-center f-bold text-2xl py-20">
        <p className="text-[#CD1818]">{t("sorryNoUser")}</p>
        <div className="text-blue-500 underline">
          <Link to={`/login`}>{t("canLoginHere")}</Link>
        </div>
        <div className="text-blue-500 mt-4 underline">
          <Link to={`/register`}>{t("orCreate")}</Link>
        </div>
      </div>
    );
  return (
    <>
      <NavComponent
        firstURL={`/subscriptions`}
        firstName={t("subscriptions")}
      />
      <main className="my-8 px-3 min-h-screen">
        <div className="flex flex-col items-center w-fit mx-6 tab:mx-0">
          <h1 className="f-bold text-2xl ">{t("subscriptions")}</h1>
          <div>
            <img src="/imgs/curve.png" alt="curve" className="w-auto" />
          </div>
        </div>
        <section className="my-12">
          <div>
            <div className="flex items-start justify-center gap-3">
              <p
                className={`f-bold text-xl cursor-pointer ${
                  secState === "packages" ? "opacity-100" : "opacity-50"
                }`}
                onClick={() => setSecState("packages")}
              >
                {t("packages")}
              </p>
              <div className="w-[1px] h-9 rotate-180 bg-gray-600"></div>
              <p
                className={`f-bold text-xl cursor-pointer ${
                  secState === "courses" ? "opacity-100" : "opacity-50"
                }`}
                onClick={() => setSecState("courses")}
              >
                {t("courses")}
              </p>
            </div>
            <div className="my-8">
              {secState === "packages" && (
                <div className="packages">
                  {packages?.length > 0 ? (
                    packages?.map((pack, i) => (
                      <SubscriptionsBox
                        key={i}
                        item={pack}
                        WhereLink={`packages`}
                      />
                    ))
                  ) : (
                    <EmptySubscriptions />
                  )}
                </div>
              )}
              {secState === "courses" && (
                <div className="courses">
                  {courses?.length > 0 ? (
                    courses?.map((course, i) => (
                      <SubscriptionsBox
                        key={i}
                        item={course}
                        WhereLink={`courses`}
                      />
                    ))
                  ) : (
                    <EmptySubscriptions />
                  )}
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
export default SubscriptionsView;
