import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const SignUpHook = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [formDT, setFormDT] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    rememberMe: false,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [loggedInMSG, setLoggedInMSG] = useState(false);

  const MakeChanges = (e) => {
    const { name, value, type, checked } = e.target;
    setFormDT({
      ...formDT,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const CreateAccount = async (first_name, last_name, email, password) => {
    // Validate input parameters
    if (!first_name || !last_name || !email || !password) {
      setError(
        "All fields (first_name, last_name, email, password) are required."
      );
    }

    // Optional: Validate email format using regex
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      throw new Error("Invalid email format.");
    }

    const REGISTER_URL = process.env.REACT_APP_REGISTER_API_URL;

    try {
      const response = await axios.post(REGISTER_URL, {
        first_name,
        last_name,
        email,
        password,
      });

      // Check if registration is successful
      if (response.status === 200 && response.data?.msg === "success") {
        return response.data;
      } else {
        throw new Error(
          `Registration failed: ${response.data?.msg || "Unknown error"}`
        );
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status code outside the 2xx range
        setError(
          error.response?.data?.data?.email?.[0] ||
            error.response?.data?.msg ||
            "Registration failed. Please try again."
        );
      } else if (error.request) {
        // No response received
        setError(
          "Network error: Unable to reach the server. Please check your internet connection and try again."
        );
      } else {
        // Other errors like issues during setting up the request
        setError(error.message);
      }
    }
  };

  const register = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await CreateAccount(
        formDT.firstName,
        formDT.lastName,
        formDT.email,
        formDT.password
      );
      Cookies.set("token", response.api_token);
      Cookies.set("user", JSON.stringify(response.data));
      window.location.href = "/profile";
      return response.data;
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Cookies.get("token")) {
      setLoggedInMSG(true);
      const loggedInTimer = setTimeout(() => {
        navigate("/profile");
      }, 4000);
      return () => clearTimeout(loggedInTimer);
    }
  }, [navigate]);

  return { loggedInMSG, error, t, register, formDT, MakeChanges, loading };
};

export default SignUpHook;
