import {
  GetCourses,
  GetFilteredCourses,
} from "../../store/slices/CoursesSlice";
import useFetchData from "../../hooks/useFetchData";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const UseGetCourses = () => {
  const fetchFunction = GetCourses;
  const selector = (state) => state.coursesReducer.courses;
  const params = "";
  return useFetchData(fetchFunction, params, selector, []);
};

export const UseGetFilteredCourses = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(1);
  const [pagination, setPagination] = useState();
  const firstRender = useRef(true);
  const dispatch = useDispatch();
  const res = useSelector((state) => state.coursesReducer.filteredCourses);

  const getData = async (queryString) => {
    setLoading(true);
    try {
      await dispatch(GetFilteredCourses(queryString));
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const onPressPaginate = (page) => {
    getData(`page=${page}`);
  };

  useEffect(() => {
    if (firstRender.current) {
      getData();
      firstRender.current = false;
    }
  }, []);

  useEffect(() => {
    if (!loading && res) {
      if (res?.data?.msg === "success") {
        setData(res?.data?.data?.data);
        setDataCount(res?.data?.data?.data?.length);
        setPagination(res?.data?.pagination);
      }
    }
  }, [loading, res]);

  return { loading, data, dataCount, pagination, onPressPaginate, error };
};

export const FilterHook = () => {
  const dispatch = useDispatch();
  const [category, setCategory] = useState([]);
  const [lang, setLang] = useState([]);
  const [rate, setRate] = useState([]);
  const [price, setPrice] = useState([]);
  const [price_sort, setPrice_sort] = useState();
  const [page, setPage] = useState();

  // const createCheckboxHandler = (setter) => {
  //   return (e) => {
  //     if (e.target.checked) {
  //       setter((prev) => [...prev, e.target.value]);
  //     } else {
  //       setter((prev) => prev.filter((item) => item !== e.target.value));
  //     }
  //   };
  // };

  const Navigate = useNavigate();

  const createCheckboxHandler = useCallback((setter) => {
    if (window.location.pathname === "/courses") {
      return (e) => {
        if (e.target.checked) {
          setter(e.target.value);
        }
      };
    } else {
      Navigate("/courses");
    }
  }, []);

  // handle category
  const handleChangeCategory = (e) => {
    if (window.location.pathname === "/courses") {
      if (e.target.checked) {
        setCategory((prev) => [...prev, e.target.value]);
      } else {
        setCategory((prev) => prev.filter((item) => item !== e.target.value));
      }
    } else {
      Navigate("/courses");
    }
  };
  // handle price
  const handleChangePrice = (e) => {
    if (window.location.pathname === "/courses") {
      if (e.target.checked) {
        setPrice((prev) => [...prev, e.target.value]);
      } else {
        setPrice((prev) => prev.filter((item) => item !== e.target.value));
      }
    } else {
      Navigate("/courses");
    }
  };
  // handle lang
  const handleChangeLang = (e) => {
    if (window.location.pathname === "/courses") {
      if (e.target.checked) {
        setLang((prev) => [...prev, e.target.value]);
      } else {
        setLang((prev) => prev.filter((item) => item !== e.target.value));
      }
    } else {
      Navigate("/courses");
    }
  };
  // handle lang
  const handleChangeRate = (e) => {
    if (window.location.pathname === "/courses") {
      if (e.target.checked) {
        setRate((prev) => [...prev, e.target.value]);
      } else {
        setRate((prev) => prev.filter((item) => item !== e.target.value));
      }
    } else {
      Navigate("/courses");
    }
  };
  // handle price sort
  const handleChangePriceSort = (e) => {
    if (e) {
      setPrice_sort(e.target.value);
    }
  };
  // handle reset
  const handleReset = () => {
    setCategory("");
    setPrice("");
    setPrice_sort("");
    setRate("");
    setLang("");
    setPage("");
    // Resetting all checkboxes using DOM
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false; // Uncheck all checkboxes
    });
    dispatch(GetFilteredCourses());
  };

  // useEffect(() => {
  //   try {
  //     dispatch(
  //       GetFilteredCourses(
  //         `category_id=${category}&rating=${rate}&course_type=${price}&price_sort=${price_sort}&page=${page}`
  //       )
  //     );
  //   } catch (e) {}
  // }, [category, rate, lang, price, price_sort, page]);

  useEffect(() => {
    try {
      // Construct the query parameters dynamically for each array
      const queryParams = [];

      // Add category array to query string
      if (category && category.length > 0) {
        category.forEach((item) => {
          queryParams.push(`category_id[]=${encodeURIComponent(item)}`);
        });
      }

      // Add rate array to query string
      if (rate && rate.length > 0) {
        rate.forEach((item) => {
          queryParams.push(`rating[]=${encodeURIComponent(item)}`);
        });
      }

      // Add price array to query string
      if (price && price.length > 0) {
        price.forEach((item) => {
          queryParams.push(`course_type[]=${encodeURIComponent(item)}`);
        });
      }

      // Add price_sort array to query string
      if (price_sort && price_sort.length > 0) {
        price_sort.forEach((item) => {
          queryParams.push(`price_sort[]=${encodeURIComponent(item)}`);
        });
      }

      // Add page array to query string (assuming page might also be an array)
      if (page && page.length > 0) {
        page.forEach((item) => {
          queryParams.push(`page=${encodeURIComponent(item)}`);
        });
      }

      // Add lang array to query string
      if (lang && lang.length > 0) {
        lang.forEach((item) => {
          queryParams.push(`locale=${encodeURIComponent(item)}`);
        });
      }

      // Combine all query parameters into one query string
      const queryString = queryParams.join("&");

      // Dispatch the action with the generated query string
      dispatch(GetFilteredCourses(queryString));
    } catch (e) {
      // console.error("Error in dispatching GetFilteredCourses", e);
    }
  }, [category, rate, lang, price, price_sort, page]);

  return {
    handleChangeCategory,
    handleChangePrice,
    handleChangeLang,
    handleChangeRate,
    handleChangePriceSort,
    handleReset,
    category,
    lang,
    rate,
    price,
  };
};
