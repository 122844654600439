export const LangRadio = ({ arrTitle, data, handleChange }) => {
  return (
    <div className="p-3 rounded-lg bg-[#F7F7F9] text-black tab:w-full">
      <h1 className="f-bold text-xl">{arrTitle}</h1>
      <div>
        {data?.map((lang) => (
          <div key={lang?.id} className="w-full">
            <input
              type="checkbox"
              value={lang?.locale}
              name={arrTitle}
              id={`${arrTitle}-${lang?.id}`}
              onChange={handleChange}
            />
            <label
              htmlFor={`${arrTitle}-${lang?.id}`}
              className="f-rg mx-2 text-nowrap"
            >
              {lang.name}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};
