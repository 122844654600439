import React from "react";
import TrainerReviews from "./trainerReviews";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { HomeErr, ReviewsSelector } from "../../store/slices/HomeSlice";

const Reviews = () => {
  const { t } = useTranslation();
  const reviews = useSelector(ReviewsSelector);
  const error = useSelector(HomeErr);
  return (
    <section className="mainBg py-6">
      <div className="flex flex-col items-center w-fit mt-3 px-4">
        <h1 className="f-bold text-2xl ">{t("reviews")}</h1>
        <div>
          <img src="/imgs/curve.png" alt="curve" className="w-auto" />
        </div>
      </div>
      <div className="py-14 mob:py-5">
        <TrainerReviews reviews={reviews} />
        {(error || reviews?.length <= 0) && (
          <div className="f-bold text-center py-20 text-xl text-[#CD1818]">
            {t("noReviews")}
          </div>
        )}
      </div>
    </section>
  );
};

export default Reviews;
