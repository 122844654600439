import React from "react";
import { useTranslation } from "react-i18next";

const Companies = () => {
  const companies = ["/imgs/NCSF.png", "/imgs/ACE.png", "/imgs/ACSM.png"];
  const { t } = useTranslation();
  return (
    <section className="my-5">
      <div className="flex flex-col items-center w-fit my-5">
        <h1 className="f-bold text-2xl"> {t("approved")}</h1>
        <div>
          <img src="/imgs/curve.png" alt="curve" className="w-auto" />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-2.5">
        {companies?.map((company, index) => (
          <div key={index}>
            <img src={company} alt={company} className="my-2 w-full" />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Companies;
