import React from "react";
import AboutContent from "../components/About/aboutContent";
import TrainerReviews from "../components/Home/trainerReviews";
import NavComponent from "../UI/CustomUI/NavComponent";
import { useSelector } from "react-redux";
import { ReviewsSelector, SettingsSelector } from "../store/slices/HomeSlice";
import LoadingComponent from "../UI/CustomUI/LoadingComponent";
import { useTranslation } from "react-i18next";

export default function About() {
  const IMG_URL = process.env.REACT_APP_ALL_IMGS_API_URL;
  const { t } = useTranslation();
  const reviews = useSelector(ReviewsSelector);
  const settings = useSelector(SettingsSelector);

  if (!settings || !reviews)
    return (
      <LoadingComponent
        parentClass={`grid p-4`}
        childClass={`h-[28rem]`}
        childrenNumber={2}
      />
    );
  return (
    <main>
      <NavComponent firstName={t("about")} firstURL={`/about`} />
      <section className="relative my-8 px-6 overflow-hidden">
        <div className="flex flex-col items-center w-fit mx-6 tab:mx-0">
          <h1 className="f-bold text-2xl ">{t("about")}</h1>
          <div>
            <img src="/imgs/curve.png" alt="curve" className="w-auto" />
          </div>
        </div>
        <AboutContent />
        <section className="my-12">
          <div className="flex flex-col items-center w-fit">
            <h1 className="f-bold text-2xl ">{t("intro")}</h1>
            <div>
              <img src="/imgs/curve.png" alt="curve" className="w-auto" />
            </div>
          </div>
          <div className="mt-14 flex justify-center">
            <video
              controls
              src={`${IMG_URL}${settings?.video}`}
              poster="/imgs/Video.png"
              className="rounded-lg w-9/12"
            >
              <source src={`${IMG_URL}${settings?.video}`} type="video/mp4" />
              <source src={`${IMG_URL}${settings?.video}`} type="video/webm" />
              <source src={`${IMG_URL}${settings?.video}`} type="video/ogg" />
              {/* لو المتصفح مبيدعمش */}
              Your browser does not support the video tag.
            </video>
          </div>
        </section>
        {/* REVIEWS */}
      </section>
      <section className="mainBg py-4">
        <div className="flex flex-col items-center my-3 w-fit mt-3 px-4">
          <h1 className="f-bold text-2xl ">{t("reviews")}</h1>
          <div>
            <img src="/imgs/curve.png" alt="curve" className="w-auto" />
          </div>
        </div>
        <section className="py-14 mob:py-0">
          <TrainerReviews reviews={reviews} />
        </section>
      </section>
    </main>
  );
}
