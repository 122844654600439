import PackageSwiper from "./packageSwiper";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Packages = () => {
  const { t } = useTranslation();

  return (
    <main className="mainBg px-12 tab:px-2">
      <main className="p-6 tab:px-2">
        <div className="flex justify-between px-16 w-full my-5 mob:flex-col mob:gap-4 tab:px-3">
          <div className="flex flex-col items-center w-fit tab:items-start">
            <h1 className="f-bold text-2xl mob:mx-5">{t("packages")}</h1>
            <div>
              <img src="/imgs/curve.png" alt="curve" className="w-auto" />
            </div>
          </div>
          <div className="text-gray-500">
            <Link to={`/packages`} className="f-bold text-xl mob:text-lg">
              {t("watchMore")}
            </Link>
          </div>
        </div>
        <section className="my-10">
          <PackageSwiper
            boxClassName="grid gap-4 mx-2"
            seeFavourite={true}
            swiperClassName={`w-full tab:h-full mob:h-full`}
            mainBox={`w-[24rem] tab:w-full`}
          />
        </section>
      </main>
    </main>
  );
};

export default Packages;
