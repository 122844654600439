import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const NavbarHook = () => {
  const [seeMenu, setSeeMenu] = useState(false);
  const [seeProfileDT, setSeeProfileDT] = useState(false);
  const [theme, setTheme] = useState("");
  const [lang, setLang] = useState(localStorage.getItem("i18nextLng") || "en");
  const { t, i18n } = useTranslation();
  const { user, token } = useSelector((state) => state.profile);

  const isEnglish = lang === "en";
  const toggleSeeMenu = () => {
    setSeeMenu((prev) => !prev);
  };

  const closeMenuOnNavigate = () => {
    setSeeMenu(false);
  };

  const changeLang = (newLang) => {
    i18n.changeLanguage(newLang);
    localStorage.setItem("i18nextLng", newLang);
    setLang(newLang);
    window.location.reload();
  };

  const makeTheme = (mode) => {
    localStorage.setItem("theme", mode);
    setTheme(mode);
    document.body.setAttribute("theme", mode);
  };

  useEffect(() => {
    if (user && token) {
      setSeeProfileDT(true);
    } else {
      setSeeProfileDT(false);
    }
  }, [user]);

  useEffect(() => {
    setTheme(localStorage.getItem("theme") || "light");
  }, []);

  return {
    seeMenu,
    toggleSeeMenu,
    isEnglish,
    seeProfileDT,
    t,
    changeLang,
    theme,
    lang,
    makeTheme,
    closeMenuOnNavigate,
  };
};

export default NavbarHook;
