import { useState } from "react";
import { useSelector } from "react-redux";
import NavComponent from "../../UI/CustomUI/NavComponent";
import CourseBox from "../../UI/CoursesUI/courseBox";
import { HomeErr, HomeLoading } from "../../store/slices/HomeSlice";
import LoadingComponent from "../../UI/CustomUI/LoadingComponent";

import { useTranslation } from "react-i18next";
import {
  FilterHook,
  UseGetFilteredCourses,
} from "../../components/Courses/hooks";
import CustomPagination from "../../components/Util/CustomPagination";
import FilterSidebar from "../../components/Util/FilterSidebar";
import MobileFilterSidebar from "../../components/Util/MobileFilterSidebar";

const Courses = () => {
  const [seeSideBar, setSeeSideBar] = useState(false);
  const { t } = useTranslation();

  const seeBar = () => {
    setSeeSideBar((prevBool) => !prevBool);
  };
  const homeErr = useSelector(HomeErr);
  const homeLoading = useSelector(HomeLoading);

  const { handleChangePriceSort } = FilterHook();

  const { loading, data, dataCount, pagination, onPressPaginate } =
    UseGetFilteredCourses();

  if (homeErr)
    return (
      <div className="text-2xl text-center m-auto py-11">
        <span className="font-bold">404 | ERROR</span>
        <p className="my-5 f-bold">{t("soryyNoData")}</p>
      </div>
    );

  if (homeLoading)
    return (
      <LoadingComponent
        parentClass={`grid grid-cols-4 p-4 gap-4`}
        childClass={[
          "h-[10rem] rounded-xl col-span-4",
          `h-svh rounded-xl col-span-1`,
          `h-svh rounded-xl col-span-3`,
        ]}
        childrenNumber={3}
      />
    );

  return (
    <main>
      <NavComponent firstName={t("courses")} firstURL={`/courses`} />
      <section className="my-8 px-3">
        {seeSideBar && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-10"
            onClick={seeBar}
          />
        )}
        <div className="flex flex-col items-center w-fit mx-6 tab:mx-0 tab:items-start">
          <h1 className="f-bold text-2xl mx-4">{t("courses")}</h1>
          <div>
            <img src="/imgs/curve.png" alt="curve" className="w-auto" />
          </div>
        </div>
        <main className="flex gap-20 mob:gap-5 items-start tab:mt-16 my-12">
          <div className="absolute mt-5 top-44 menu bg-[#F7F7F9] hover:bg-gray-200 transition-all duration-200  rounded-lg hidden tab:block">
            <button className="f-rg py-1 px-4" onClick={seeBar}>
              {t("menu")}
            </button>
          </div>
          {/* Filter Sidebar */}
          <FilterSidebar />
          {/* RESPONSIVE */}
          <aside
            className={`asideCourse h-full absolute hidden tab:block mob:w-[84%] tab:w-2/4 px-4 top-0 z-20 transition-all duration-150 py-12
            ${
              localStorage.getItem("i18nextLng") === "en"
                ? seeSideBar
                  ? "-translate-x-5"
                  : "-translate-x-[100rem]"
                : seeSideBar
                ? "translate-x-5"
                : "translate-x-[100rem]"
            } `}
          >
            <p
              className={`f-bold absolute top-2 cursor-pointer tab:block ${
                localStorage.getItem("i18nextLng") === "en"
                  ? "right-5"
                  : "left-5"
              }`}
              onClick={seeBar}
            >
              <i className="fas fa-times text-2xl text-[#CD1818]"></i>
            </p>
            <MobileFilterSidebar />
          </aside>
          {dataCount > 0 ? (
            <main className="m-0 p-0">
              {loading ? (
                <LoadingComponent
                  parentClass={`grid grid-cols-2 w-[70vw] p-4 gap-6 mob:grid-cols-1`}
                  childClass={`h-80 rounded-xl w-full`}
                  childrenNumber={4}
                />
              ) : (
                <>
                  <div className="my-4">
                    <select
                      name="menu"
                      id="menu"
                      className="f-rg w-40 bg-[#F7F7F9] text-black rounded-md px-2 outline-none"
                      defaultValue=""
                      onChange={handleChangePriceSort}
                    >
                      <option value="" disabled>
                        {t("defOrder")}
                      </option>
                      <option value="asc">{t("lowToHeigh")}</option>
                      <option value="desc">{t("heighToLow")}</option>
                    </select>
                  </div>
                  <div className="min-h-screen grid grid-cols-3 gap-4 w-full mob:grid-cols-1 tab:gap-8 tab:h-full">
                    {data?.map((course) => (
                      <CourseBox
                        key={course.id}
                        course={course}
                        className={`w-full h-[19rem] object-cover`}
                        boxWidth={`h-fit`}
                      />
                    ))}
                  </div>
                  <CustomPagination
                    totalPages={pagination?.last_page}
                    currentPage={pagination?.current_page}
                    onChange={onPressPaginate}
                  />
                </>
              )}
            </main>
          ) : (
            <div className="text-center f-bold text-2xl text-[#CD1818] pt-10">
              {t("sorryNoDT")}
            </div>
          )}
        </main>
      </section>
    </main>
  );
};

export default Courses;
