import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

const useFetchData = (fetchFunction, params, selector, initialState = []) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(initialState);
  const [dataCount, setDataCount] = useState(0);
  const [meta, setMeta] = useState(null);
  const firstRender = useRef(true);
  const dispatch = useDispatch();
  const res = useSelector(selector);

  const getData = async () => {
    setLoading(true);
    try {
      await dispatch(fetchFunction(params));
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const onPressPaginate = (page) => {
    getData(page);
  };

  useEffect(() => {
    if (firstRender.current) {
      getData();
      firstRender.current = false;
    }
  }, []);

  useEffect(() => {
    if (!loading && res) {
      if (res?.data?.msg === "success") {
        setData(res?.data?.data?.data);
        setDataCount(res?.data?.data?.data?.length);
        setMeta(res?.data?.meta);
      }
    }
  }, [loading, res]);

  return { loading, data, dataCount, meta, onPressPaginate, error };
};
export default useFetchData;
