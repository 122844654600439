import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const HOME_URL =
  process.env.REACT_APP_HOME_DATA_API_URL +
  `?locale=${localStorage?.getItem("i18nextLng") || "ar"} `;

// FETCHING DATA
export const fetchHomeData = createAsyncThunk(
  "homeSlice/fetchHomeData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(HOME_URL);
      return response?.data?.data;
    } catch (error) {
      // HANDLING ERRORS
      return rejectWithValue(
        error.response?.data?.data || "Something went wrong"
      );
    }
  }
);

const initialState = {
  courses: [],
  packages: [],
  results: [],
  reviews: [],
  settings: [],
  loading: false,
  error: null,
};

const homeSlice = createSlice({
  name: "homeSlice",
  initialState: initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHomeData.fulfilled, (state, action) => {
        const { courses, packages, results, reviews, settings } =
          action.payload;
        state.courses = courses;
        state.packages = packages;
        state.results = results;
        state.reviews = reviews;
        state.settings = settings;
        state.loading = false;
      })
      .addCase(fetchHomeData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const CoursesSelector = (state) => state.home.courses;
export const PackagesSelector = (state) => state.home.packages;
export const ResultsSelector = (state) => state.home.results;
export const ReviewsSelector = (state) => state.home.reviews;
export const SettingsSelector = (state) => state.home.settings;
export const HomeLoading = (state) => state.home.loading;
export const HomeErr = (state) => state.home.error;

export default homeSlice.reducer;
