import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SettingsSelector } from "../../store/slices/HomeSlice";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import axios from "axios";

export default function Footer() {
  const CONTACT_URL = process.env.REACT_APP_CONTACT_US_API;

  const { t } = useTranslation();
  const settings = useSelector(SettingsSelector);
  const [email, setEmail] = useState("");
  const [mailLoading, setMailLoading] = useState(false);

  const SubmitFunc = async (e) => {
    e.preventDefault();
    setMailLoading(true);
    try {
      await axios.post(CONTACT_URL, {
        name: "رساله من مشترك جديد",
        email: email,
        title: "عنوان",
        content: "اريد الاشتراك معكم",
      });
    } catch (err) {
      alert("ERROR : " + err);
    } finally {
      setMailLoading(false);
    }
  };

  return (
    <footer className="overflow-hidden">
      <main className="bg-black px-40 py-28 tab:px-5">
        <main className="flex flex-col h-fit gap-5 tab:gap-0">
          <section className="grid grid-cols-4 gap-5 tab:grid-cols-2 mob:grid-cols-1 tab:gap-0">
            <section className="m-0 p-0">
              <div>
                <Link to={`/`}>
                  <h1 className="f-bold text-2xl text-white">
                    <span className="text-[#CD1818] f-bold text-2xl">
                      DULIJAN{" "}
                    </span>
                    ACADEMY
                  </h1>
                </Link>
              </div>
              <div>
                <p className="f-rg text-gray-400 my-4">
                  {/* {t("demo.0")} */}
                  {settings?.about_us?.slice(1, 150)}
                  {settings?.Terms_conditions?.slice(1, 150)}
                </p>
              </div>
              <div className="f-rg text-gray-400 my-2">
                <p className="my-4">{settings?.address}</p>
                <a href={`tel:+${settings?.phone}`} dir="ltr">
                  +
                  {settings?.phone?.replace(
                    /(\d{3})(\d{3})(\d{3})(\d{3})/,
                    "$1 $2 $3 $4"
                  )}
                </a>
              </div>
            </section>

            <section className="m-0 p-0">
              <h2 className="f-bold text-white footerH2 w-fit">
                {t("usefulLinks")}
              </h2>
              <ul className="text-gray-400 p-0">
                <li className="hover:text-white w-fit">
                  <Link to="/packages" className="f-rg">
                    {t("packages")}
                  </Link>
                </li>
                <li className="hover:text-white w-fit">
                  <Link to="/courses" className="f-rg">
                    {t("courses")}
                  </Link>
                </li>
              </ul>
            </section>

            <section className="m-0 p-0">
              <h2 className="f-bold text-white footerH2 w-fit">
                {t("aboutAcademy")}
              </h2>
              <ul className="text-gray-400 p-0">
                <li className="hover:text-white w-fit">
                  <a href={`tel:+${settings?.phone}`} className="f-rg">
                    {t("callus")}
                  </a>
                </li>
                <li className="hover:text-white w-fit">
                  <Link to="/about" className="f-rg">
                    {t("privacy")}
                  </Link>
                </li>
                <li className="hover:text-white w-fit">
                  <Link to="/about" className="f-rg">
                    {t("terms")}
                  </Link>
                </li>
              </ul>
            </section>

            <section className="m-0 p-0">
              <h2 className="f-bold text-white footerH2 w-fit">
                {t("contactUs")}
              </h2>
              <span className="text-gray-400">
                <a href="#." className="f-rg">
                  {settings?.about_us?.slice(1, 150)}
                  {settings?.Terms_conditions?.slice(1, 150)}
                </a>
              </span>
              <div className="flex items-center gap-2 my-4">
                <a
                  href={settings?.facebook || "#"}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook text-[20px] text-[#F5F5F5] w-8 h-8"></i>
                </a>
                <a
                  href={settings?.instagram || "#"}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-instagram text-[20px] text-[#F5F5F5] w-8 h-8"></i>
                </a>
                <a
                  href={`https://wa.me/+${settings?.phone}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-whatsapp text-[20px] text-[#F5F5F5] w-8 h-8"></i>
                </a>
                <a href={`tel:+${settings?.phone}`} className="f-rg">
                  <i className="fa-solid fa-phone text-[20px] text-[#F5F5F5] w-8 h-8"></i>
                </a>
                <a
                  href={settings?.twitter || "#"}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-twitter text-[20px] text-[#F5F5F5] w-8 h-8"></i>
                </a>
              </div>
            </section>
          </section>

          <form method="POST" onSubmit={SubmitFunc}>
            <div className="bg-[#181818] rounded-md flex items-center justify-between p-2 w-[22rem] tab:w-full">
              <input
                type="email"
                title={t("enterEmail")}
                placeholder={t("enterEmailPlaceholder")}
                id="footerMail"
                className="w-full f-semibold"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
              {mailLoading ? (
                <div className="animate-spin rounded-full border-t-2 border-b-2 border-white h-7 w-7 mr-2"></div>
              ) : (
                <button type="submit">
                  <i
                    className="fa-solid fa-paper-plane bg-white text-[#CD1818] transform p-2 rounded-md"
                    id="send"
                  ></i>
                </button>
              )}
            </div>
          </form>
        </main>
      </main>
      <div className="bg-[#FB0100] p-4">
        <p className="f-bold text-xl">{t("allRightsReserved")}</p>
      </div>
    </footer>
  );
}
