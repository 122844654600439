import { useTranslation } from "react-i18next";
import FalseValidate from "../../components/Paying/FalseValidate";
import NavComponent from "../../UI/CustomUI/NavComponent";

const PayingFailedView = () => {
  const { t } = useTranslation();

  return (
    <main>
      <NavComponent
        firstURL={`/packages`}
        firstName={t("packages")}
        secName={t("pay")}
      />
      <section className="p-6 flex justify-center items-center mob:p-2">
        <FalseValidate />
      </section>
    </main>
  );
};
export default PayingFailedView;
