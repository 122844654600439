import React from "react";
import { useSelector } from "react-redux";
import {
  HomeErr,
  HomeLoading,
  ResultsSelector,
} from "../../store/slices/HomeSlice";
import { useTranslation } from "react-i18next";
import LoadingComponent from "../../UI/CustomUI/LoadingComponent";

const Results = () => {
  const results = useSelector(ResultsSelector);
  const error = useSelector(HomeErr);
  const loading = useSelector(HomeLoading);
  const { t } = useTranslation();
  const IMG_URL = process.env.REACT_APP_ALL_IMGS_API_URL;
  return (
    <main className="p-8">
      <div className="flex flex-col items-center w-fit my-5">
        <h1 className="f-bold text-2xl ">{t("results")}</h1>
        <div>
          <img src="/imgs/curve.png" alt="curve" className="w-auto" />
        </div>
      </div>
      {/* CSS SCROLLBAR (NO NEED SWIPER) */}
      <section className="grid grid-cols-3 gap-3 justify-items-center tab:flex tab:scroll-smooth tab:justify-start tab:snap-x mob:overflow-x-auto hide-scrollbar">
        {results?.map((res) => (
          <div key={res?.id}>
            <div className="bg-[#e4e4e4] w-[20.6875rem] h-[17.75rem]">
              <img
                src={`${IMG_URL}${res?.image}`}
                alt={`trainer-${res?.id}`}
                className="max-w-[20.6875rem] max-h-[16rem] object-cover"
              />
            </div>
          </div>
        ))}
        {!results && loading && (
          <LoadingComponent
            parentClass={`grid grid-cols-3 gap-3 justify-items-center tab:flex tab:scroll-smooth tab:justify-start tab:snap-x tab:overflow-x-auto hide-scrollbar`}
            childClass={`h-[20rem]`}
            childrenNumber={4}
          />
        )}
        {(error || results?.length <= 0) && (
          <div className="f-bold text-center py-20 text-xl text-[#CD1818]">
            {t("noResults")}
          </div>
        )}
      </section>
    </main>
  );
};

export default Results;
