import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { GetCourseQuiz, GetPackageQuiz } from "../../store/slices/QuizSlice";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const UseGetQuizHook = () => {
  const dispatch = useDispatch();
  const res = useSelector((state) =>
    whatItem === "courses"
      ? state.quizReducer.courseQuiz
      : state.quizReducer.packageQuiz
  );
  const { subscriptionId, whatItem, day } = useParams();
  const [quiz, setQuiz] = useState();
  useEffect(() => {
    if (Cookies.get("token")) {
      try {
        dispatch(
          whatItem === "courses"
            ? GetCourseQuiz(day?.slice(day?.length - 1, day?.length))
            : GetPackageQuiz(day?.slice(day?.length - 1, day?.length))
        );
      } catch (e) {}
    } else {
      return;
    }
  }, [dispatch, subscriptionId, whatItem]);

  useEffect(() => {
    if (res?.data?.msg === "success") {
      setQuiz(res?.data?.data);
    }
  }, [res]);

  return { quiz };
};

export default UseGetQuizHook;
