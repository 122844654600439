import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CategoryBox } from "../../UI/CoursesUI/CourseSideBarBoxs/CategoryBox";
import { LangRadio } from "../../UI/CoursesUI/CourseSideBarBoxs/LangRadio";
import { PriceRadio } from "../../UI/CoursesUI/CourseSideBarBoxs/PriceRadio";
import { RatingRadio } from "../../UI/CoursesUI/CourseSideBarBoxs/RatingRadio";
import { FilterHook } from "../Courses/hooks";
import LoadingComponent from "../../UI/CustomUI/LoadingComponent";
import { useDispatch, useSelector } from "react-redux";
import { GettingCategories } from "../../store/slices/ChoosedDTSlice";

const MobileFilterSidebar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { categories, categoriesLoading, categoriesErr } = useSelector(
    (state) => state.ChoosedDT
  );

  const langs = [
    { id: 1, locale: "", name: t("allLngs") },
    { id: 2, locale: "ar", name: t("ar") },
    { id: 3, locale: "en", name: t("en") },
  ];
  const prices = [
    { id: "", name: t("allCat") },
    { id: "free", name: t("free") },
    { id: "paid", name: t("paid") },
  ];
  const rates = [5, 4, 3, 2, 1];
  // RENDER CATEGORIES
  useEffect(() => {
    dispatch(GettingCategories());
  }, []);
  const {
    handleChangeCategory,
    handleChangePrice,
    handleChangeRate,
    handleReset,
  } = FilterHook();
  return (
    <aside className="grid gap-4 w-3/12 p-2">
      {categoriesLoading ? (
        <LoadingComponent
          parentClass={`grid gap-4 w-full`}
          childClass={"h-[8rem] rounded-lg w-full"}
          childrenNumber={4}
        />
      ) : (
        <>
          <CategoryBox
            data={categories}
            handleChange={handleChangeCategory}
            title={t("categories")}
          />
          <LangRadio data={langs} arrTitle={t("lang")} />
          <PriceRadio
            data={prices}
            arrTitle={t("price")}
            handleChange={handleChangePrice}
          />
          <RatingRadio
            data={rates}
            arrTitle={t("theRate")}
            handleChange={handleChangeRate}
          />
          <button
            className="bg-[#F7F7F9] text-black px-4 py-1 f-rg rounded-lg text-nowrap hover:bg-gray-300 transition-all duration-200"
            onClick={handleReset}
          >
            {t("reset")}
          </button>
        </>
      )}
      {categoriesErr && (
        <div className="f-bold text-xl text-[#CD1818]">{t("soryyNoData")}</div>
      )}
    </aside>
  );
};

export default MobileFilterSidebar;
