import Landing from "../components/Home/Landing";
import { useSelector } from "react-redux";
import { HomeErr, HomeLoading } from "../store/slices/HomeSlice";
import LoadingComponent from "../UI/CustomUI/LoadingComponent";
import { useTranslation } from "react-i18next";
import HomeAbout from "../components/Home/HomeAbout";
import WhatWeDo from "../components/Home/WhatWeDo";
import Companies from "../components/Home/Companies";
import Reviews from "../components/Home/Reviews";
import Results from "../components/Home/Results";
import Courses from "../components/Home/Courses";
import Packages from "../components/Home/Packages";

const Home = () => {
  const { t } = useTranslation();
  const error = useSelector(HomeErr);
  const loading = useSelector(HomeLoading);

  //  LOADING
  if (loading)
    return (
      <LoadingComponent
        parentClass={`grid grid-cols-2 gap-3 mob:grid-cols-1 w-full p-3`}
        childrenNumber={2}
        childClass={`h-[28rem] w-full rounded-lg`}
      />
    );

  // ERROR
  if (error && !loading)
    return (
      <div className="f-bold text-2xl text-[#CD1818] text-center pt-20">
        {t("soryyNoData")}
        <p className="my-4">{error}</p>
      </div>
    );

  return (
    <main>
      <Landing />
      <Packages />
      <Courses />
      <div className="py-3 px-5 my-6">
        {/* ABOUT */}
        <HomeAbout />
        {/* WHAT WE DO */}
        <WhatWeDo />
        {/* COMPANIES */}
        <Companies />
        {/* REVIEWS */}
        <Reviews />
        {/* RESULTS */}
        <Results />
      </div>
    </main>
  );
};
export default Home;
